import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles/TermsAndConditionsStyles';

let isOn = false;

export function Prices(): JSX.Element {
  const classes = useStyles();

  const [state, update] = useState(25000);

  const GeUSD_IRRandUSDT = async () => { // every 2 seconds
    const url = "https://www.tgju.org/?urd=" + (Math.floor(Math.random() * 1000) + 1000);
    var out2 = await fetch(url);

    const out = await out2.text();

    if (out) {
      let price: any = out.match(/data\-index\=\"price_dollar_rl\"[\s\S]+?قیمت دلار[\s\S]+?table\-header\-summary\-bottom\-price\s+[\s\S]+?\<div\>([\s\S]+?)\<\/div\>/);
      const rl = (+price[1].replace(/\,/g, ""));
      if (price) {
        update(+rl / 10);
        // list.push({ symbol: "TOMAN", price: 1 / (rl / 10) });
      }
    }


  }

  if (!isOn) {
    GeUSD_IRRandUSDT();
    setInterval(() => {
      GeUSD_IRRandUSDT();
    }, 30 * 60 * 60 * 1000);
  }
  return (
    <div className={classes.root} style={{ background: "rgba(0, 0, 0, 0.09)" }}>
      <div className={classes.overlay} />
      <div className={classes.image} />
      <div className={classes.header}>
        <p>
          کارمزد ها و کیف پول
        </p>
      </div>
      <div>
        <Container className={classes.container}>
          <Typography align="right">
            <h1>قوانین کیف پول</h1>
            <ol type="1">
              <li>
                کیف پول های ارزهای دیجیتال برخلاف دیگر سامانه ها ، به هیچ گونه سامانه خارجی وابسته نمی باشد و تمامی ارز ها در کیف پول های سخت افزاری در ایران نگه داری میگردد.
              </li>
              <li>
                برداشت های کیف پول حداکثر تا یک ساعت پس از درخواست برداشت انجام خواهند پذیرفت.
              </li>
              <li>
                باتوجه به سیکل های تسویه پایا در کشور بنابر ابلاغیه بانک مرکزی، زمانهای تسویه ریالی اینتکس به شرح زیر است:
                <ol type="1">
                  <li>
                    درخواست های برداشت ریال صرفاً در روزهای کاری پردازش میشوند و در روزهای تعطیل بدلیل از سرویس خارج بودن شبکه های ساتنا و پایا امکان تسویه به جز در موارد خاص وجود ندارد.
                  </li>
                  <li>
                    درخواست های ثبت شده تا ساعت 12:30، در سیکل 13:45 توسط بانک مرکزی پردازش و معمولا با نیم تا یک ساعت تاخیر به حساب کاربران واریز خواهد شد.
                  </li>
                  <li>
                    درخواست های ثبت شده بعد از ساعت 12:30 تا ساعت 22، در روز کاری بعد در ساعت 4 الی 7 صبح به حساب کاربران محترم واریز خواهد شد.️
                  </li>
                  <li>
                    درخواستهای ثبت شده بعد از ساعت 22 تا 9 صبح، در ساعت 10:45 دقیقه صبح توسط بانک مرکزی پردازش و معمولا با نیم تا یک ساعت تاخیر به حساب کاربران محترم واریز خواهد شد.
                  </li>
                </ol>
              </li>
              <li>
                درصورتی که کاربران بعد از معامله و انجام ترنزاکشن ها ، در کیف پول های خود ، میزان غیر قابل برداشتی از موجودی داشتند، به منظور دریافت باقی مانده وجه می توانند با پشتیبانی تماس حاصل نمایند.
              <br />
                <a href="mailto:support@intexchange.ir">support@intexchange.ir</a>
              </li>
            </ol>

          </Typography>
        </Container>
        <Container className={classes.container}>
          <Typography align="right">
            <h1>واریز و برداشت ریالی</h1>
            <p>
              کارمزد واریز ارز به کیف پول در سامانه صفر می باشد.
            <br />
            برداشت ریالی به دلیل وجود کارمزد های بانکی به صورت زیر محاسبه می گردد:
            </p>
            <p>
              در نظر داشته باشید زمان پردازش سیکل پایا از سوی بانک مرکزی با زمان واریز به حساب شما متفاوت خواهد بود و معمولا با تاخیر 30 تا 45 دقیقه به حساب مقصد واریز میگردد.
            </p>

            <table>
              <thead>
                <tr>
                  <th>حجم برداشت تومان</th>
                  <th >هزینه برداشت</th>
                </tr>
              </thead>
              <tbody >
                <tr >
                  <td >کمتر از ۵۰۰ هزار تومان</td>
                  <td  >۱ درصد</td>
                </tr>
                <tr >
                  <td >500 هزار تومان تا 100 میلیون تومان</td>
                  <td  >۵ هزار تومان</td>
                </tr>
                <tr >
                  <td >بیشتر از 100 میلیون تومان</td>
                  <td  >به ازای هر ۱۰۰ میلیون تومان 5 هزار تومان</td>
                </tr>
              </tbody>
            </table>
          </Typography>
        </Container>
        <Container className={classes.container}>
          <Typography align="right">
            <h1>کارمزد ها</h1>
            <p>
              کارمزد های خرید فروش در بازار اصلی سامانه، به صورت یک سان از هر سو گرفته می شود. موارد تومانی زیر با دلار {new Intl.NumberFormat().format(state)} تومان محاسبه گردیده است.
            <br />
            به طور پیش فرض حجم معاملات از تاریخ ثبت نام محاسبه می گردد
            </p>
            <p>
              حجم معاملات انجام شده در سامانه توسط کاربر به صورت دلاری محاسبه می گردد. این محاسبه بر اساس قیمت ثبت شده در لحظه معامله صورت میگیرد و مجموع حجم های دلاری به صورت خودکار توسط سامانه بررسی و اعمال میگردد.
            </p>

            <table>
              <thead>
                <tr>
                  <th>دارایی دیجیتال</th>
                  <th >هزینه برداشت</th>
                  <th >حداقل قابل برداشت</th>
                </tr>
              </thead>
              <tbody >
                <tr >
                  <td >بیت‌کوین</td>
                  <td  >0.0005 BTC</td>
                  <td  >0.0009 BTC</td>
                </tr>
                <tr >
                  <td >اتریوم</td>
                  <td  >متغیر بنا به شرایط شبکه</td>
                  <td  >0.025</td>
                </tr>
                <tr >
                  <td >لایت‌کوین</td>
                  <td  >0.001 LTC</td>
                  <td  >0.002 LTC</td>
                </tr>
                <tr >
                  <td >تتر - شبکه اتریوم</td>
                  <td >متغیر بنا به شرایط شبکه</td>
                  <td  >150 USDT</td>
                </tr>
                <tr >
                  <td >تتر - شبکه ترون</td>
                  <td >1 USDT</td>
                  <td  >15 USDT</td>
                </tr>
                <tr >
                  <td >ریپل</td>
                  <td  >0.09 XRP</td>
                  <td  >25 XRP</td>
                </tr>
                <tr >
                  <td >بیت‌کوین کش</td>
                  <td  >0.0001 BCH</td>
                  <td  >0.01 BCH</td>
                </tr>
                <tr >
                  <td >استلار</td>
                  <td  >0.01 XLM</td>
                  <td  >50 XLM</td>
                </tr>
                <tr >
                  <td >ترون</td>
                  <td  >1 TRX</td>
                  <td  >100 TRX</td>
                </tr>
                <tr >
                  <td >دوج کوین</td>
                  <td  >10 DOGE</td>
                  <td  >50 DOGE</td>
                </tr>
                <tr >
                  <td >تزوس</td>
                  <td  >0.1 XTZ</td>
                  <td  >10 XTZ</td>
                </tr>
                <tr >
                  <td >دش</td>
                  <td  >0.002 DASH</td>
                  <td  >0.05 DASH</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tr>
                <th>حجم معاملات (دلار)</th>
                <th>حجم معاملات (تومان)</th>
                <th>کارمزد (٪)</th>
              </tr>
              <tr>
                <td>0 - 15,000</td>
                <td>0 - {new Intl.NumberFormat().format(state * 15000)}</td>
                <td>0.1</td>
              </tr>
              <tr>
                <td>15,000 - 50,000</td>
                <td>{new Intl.NumberFormat().format(state * 15000)} - {new Intl.NumberFormat().format(state * 50000)}</td>
                <td>0.06</td>
              </tr>
              <tr>
                <td>50,000 به بالا</td>
                <td>{new Intl.NumberFormat().format(state * 50000)} به بالا</td>
                <td>0.03</td>
              </tr>
            </table>
          </Typography>
        </Container>
      </div>
    </div>
  );
}
