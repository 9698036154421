import React from 'react';
import { TermsAndConditions } from './TermsAndConditions';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import { Prices } from './Prices';

export function App(): JSX.Element {
	return (
		<Router>
			<Switch>
				<Route path="/policy">
					<TermsAndConditions />
				</Route>
				<Route path="/prices">
					<Prices />
				</Route>
				<Route path="/">
					Under Developing...
				</Route>
			</Switch>
		</Router>
	);
}
