import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';
import './index.css';
import './fonts/Shabnam.ttf'
import "./assets/app.css"


ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.querySelector('#root')
);
