import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles/TermsAndConditionsStyles';

let isOn = false;

export function TermsAndConditions(): JSX.Element {
  const classes = useStyles();

  const [state, update] = useState(25000);

  const GeUSD_IRRandUSDT = async () => { // every 2 seconds
    const url = "https://www.tgju.org/?urd=" + (Math.floor(Math.random() * 1000) + 1000);
    var out2 = await fetch(url);

    const out = await out2.text();

    if (out) {
      let price: any = out.match(/data\-index\=\"price_dollar_rl\"[\s\S]+?قیمت دلار[\s\S]+?table\-header\-summary\-bottom\-price\s+[\s\S]+?\<div\>([\s\S]+?)\<\/div\>/);
      const rl = (+price[1].replace(/\,/g, ""));
      if (price) {
        update(+rl / 10);
        // list.push({ symbol: "TOMAN", price: 1 / (rl / 10) });
      }
    }


  }

  if (!isOn) {
    GeUSD_IRRandUSDT();
    setInterval(() => {
      GeUSD_IRRandUSDT();
    }, 30 * 60 * 60 * 1000);
  }
  return (
    <div className={classes.root} style={{ background: "rgba(0, 0, 0, 0.09)" }}>
      <div className={classes.overlay} />
      <div className={classes.image} />
      <div className={classes.header}>
        <p>
          قوانین و شرایط
        </p>
      </div>
      <div>
        <Container className={classes.container}>
          <Typography align="right">
            <h1>قوانین و شرایط استفاده از سامانه اینتکس</h1>
            <p>
              کاربران سامانه اینتکس به آدرس های{" "}
              <a href="https://intexchange.ir" style={{
                textDecoration: "none",
                color: "#08979e"
              }}>intexchange.ir</a> و
              <a href="https://intex.finance" style={{
                textDecoration: "none",
                color: "#08979e"
              }}>intex.finance</a>
              ، می بایست قبل
              از انجام هرگونه فرایندی در سامانه ، قوانین، مقررات و شرایط ذیل را
              خوانده و قبول نمایند. درصورت تخطی از موارد ذکر شده، تمامی تبعات آن
              بر عهده شخص ثبت نام کننده می باشد. در این شرایط نامه، بستر مبادله
              آنلاین اینتکس را «سامانه» ، شخص ثبت نام کننده که اطلاعات ایشان در
              سامانه ثبت شده است را «کاربر» می نامیم. این توافق نامه برحسب لزوم
              و تشخیص سامانه در هرزمان قابل تغییر می باشد که به طبع آن مراتب به
              صورت ایمیل به کاربران اعلام خواهد شد. ثبت نام کاربر در سامانه و
              استفاده از امکانات آن به معنای قبول تمامی قوانین سامانه توسط کاربر
              می باشد.
            </p>
            <h3>مفاد قرارداد</h3>
            <ol type="1">
              <li>
                سامانه در ایران مستقر می باشد و هرگونه فعالیت آن منوط به قوانین
                جمهوری اسلامی است و کاربران نیز می بایست با توجه به قوانین عمل
                نمایند و هرگونه فعالیت های خارج از چهارچوب قوانین جمهوری اسلامی
                برعهده کاربر می باشد.
              </li>
              <li>
                در صورت تغییر در قوانین سامانه و پس از اعلام آن به کاربر از طریق
                ایمیل، کاربر می بایست به منظور ادامه استفاده از سامانه، قوانین و
                شرایط جدید را نیز تایید نماید.
              </li>
              <li>
                کاربران با توجه به سطح کاربری مورد نظر می بایست اطلاعات صحیح خود
                را در سامانه وارد نمایند. بدیهی است که در صورت تعمد در ارسال
                اطلاعات اشتباه بنا بر تشخیص سامانه، سامانه می تواند کاربر را
                منفصل از خدمات نماید و دارایی های کاربر را در ابتدا موقتا ضبط
                نماید. کاربر یک هفته فرصت دارد تا اطلاعات خواسته شده سامانه را
                به منظور رفع ابهامات و شبهات به سامانه تقدیم نماید. بدیهی است پس
                از طی فرایند های ذکر شده، اکانت کاربر، درصورت تایید سامانه، فعال
                خواهد شد. درصورت عدم احتمام کاربر به منظور شفاف سازی هویت خویش،
                سامانه حق ضبط کامل دارایی های کاربر را برای خود محفوظ خواهد
                دانست.
              </li>
              <li>
                به منظور شفاف سازی اطلاعات، تمامی مکالمات، پیام ها اعم از ایمیل،
                پیامک و... ، معاملات، برداشت ها و واریزی ها و ... ضبط و ذخیره
                خواهد شد. اطلاعات مذکور کاملا محرمانه می باشند و تنها درصورت
                درخواست مراجع قضایی، به ایشان تقدیم خواهد گردید.
              </li>
              <li>
                کاربر می پذیرد که اطلاعات محرمانه خود را اعم از نام کاربری،
                رمزعبور، کلید های دسترسی، اطلاعات شناسایی و هرگونه اطلاعات ،
                برنامه و یا سخت افزار ها مانند تلفن همراه، ایمیل و … را نزد خویش
                حفظ نمایند و در دسترس شخص ثالث قرار ندهد. درصورت هرگونه صدمه و
                تخلف که توسط اکانت کاربر، چه توسط خود کاربر و چه توسط شخص ثالث
                که خواه مورد تایید باشد یا خیر انجام گیرد، تمامی عواقب آن بر
                عهده شخص کاربر می باشد و سامانه هیچ گونه مسولیتی را بر عهده
                نخواهد گرفت.
              </li>
              <li>
                سامانه توسط سه لایه رمز نگاری اطلاعات، از داده های مبادله شده
                کاربر و سامانه محافظت می کند. این رمزنگاری ها برای هرکاربر به
                صورت اختصاصی صورت میگیرد که احتمال هرگونه نفوذ را به صفر برساند.
                در عین حال به منظور امنیت بیشتر دارایی های کاربر و سامانه، کاربر
                می پذیرد اپلیکیشن سامانه، وب سایت سامانه، کلید های دومرحله ای
                اعتبار سنجی و هرگونه نرم افزار و سخت افزاری که توسط سامانه ارائه
                شده است را تنها در سیستم های ایمن نصب و در جای امن نگه داری
                نماید. هرگونه تلاش برای نفوذ به سامانه که از طریق یکی از موارد
                ذکر شده (که در تملک کاربر است) صورت گیرد، عواقب آن برعهده کاربر
                می باشد.
              </li>
              <li>
                سامانه با فرض رعایت موارد ذکر شده توسط کاربر، هرگونه معامله،
                واریز و یا برداشت و دیگر تراکنش های کاربر را صحیح قلمداد خواهد
                نمود.
              </li>
              <li>
                سامانه به منظور در امان نگه داشتن دارایی ها از خطرات احتمالی
                نفوذ به سامانه، تمام دارایی ها را به صورت ذخیره سازی سرد (کیف
                پول سخت افزاری) ذخیره می نماید.
              </li>
              <li>
                کاربر متعهد می شود که از سامانه به منظور موارد غیر قانونی مانند،
                پولشویی، خرید یا فروش اسلحه، خرید یا فروش موارد مخدر و هرگونه
                موارد غیر قانونی در سطح ایران و بین الملل، استفاده ننماید.
              </li>
              <li>
                تراکنش هایی که در سامانه انجام میپذیرند غیر قابل بازگشت می
                باشند. کاربر میپذیرد که در انجام معاملات و تراکنش ها دقت لازم و
                کافی را دارا باشد و مسئولیت کامل هرگونه اشتباه در وارد کردن
                اطلاعات را بر عهده گیرد.
              </li>
              <li>
                سامانه در مواقعی که برداشت ارز هزینه بالایی برای کاربر دارد، ارز
                مذکور را در بخش برداشت غیر فعال خواهد نمود.
              </li>
              <li>
                کاربر می پذیرد هرگونه موارد مالیاتی ناشی از تراکنش ها در سامانه
                را بر عهده گیرد.
              </li>
              <li>
                در حدود قانون هیچگونه تعهد یا ضمانتی برای اموری خاص مسکوت مانده
                در این موافقنامه از حیث ارائه‌ی خدمات بر عهده نمی گیریم. علاوه
                بر این، در خصوص محصولات یا ارزش واحد پول مجازی که خود آن را صادر
                نکرده یا واگذار ننموده و همچنین پرداخت آن را ضمانت ننموده ایم،
                هیچ گونه تعهدی نخواهیم داشت
              </li>
              <li>
                کاربر می پذیرد که سامانه می تواند در هر زمان، امکاناتی از سامانه
                را موقتا یا دائما غیرفعال یا حذف نماید ویا امکاناتی را به سامانه
                اضافه نماید.
              </li>
              <li>
                کاربر میپذیرد کارمزد های سامانه را مطالعه و تایید نموده است و حق
                هیچگونه اعتراض به کارمزد های تراکنش ها را ندارد.
              </li>
              <li>
                کاربر میپذیرد که در هنگام برداشت ارز های دیجیتال ، درصورت مفقودی مبلغ منتقل شده در شبکه و یا نرسیدن آن به دلایل، اشتباه وارد کردن آدرس مقصد، ناقص وارد کردن آدرس مقصد، بلوکه شدن ارز در مقصد، پاسخ گو نبودن دریافت کننده درباره مبلغ و.. همه مسئولیت آن بر عهده ایشان می باشد و سامانه اینتکس مسئولیتی ندارد.
              </li>
              <li>
                مبالغ برداشت تومان از حساب کاربر پس از تایید توسط سامانه در سریع ترین زمان ممکن انجام خواهد پذیرفت. زمان انتقال وجه در سامانه بانکی بر اساس قوانین و محدودیت های آن سامانه می باشد و اینتکس مسولیتی در قبال تاخیر در دریافت وجه را نمی پذیرد.
              </li>
            </ol>
          </Typography>
        </Container>
        <Container className={classes.container}>
          <Typography align="right">
            <h1>فرایند احراز هویت</h1>
            <p>
              به منظور جلوگیزی از عملیات های خرابکارانه نظیر پولشویی و تبادلات مالی در موارد خلاف قانون جمهوری اسلامی، بستر اینتکس از تمامی کاربران در سطوح مختلف و بر مبنای سطح دسترسی داده شده اطلاعات هویتی کاربر را درخواست خواهد کرد. این اطلاعات بر اساس درخواست کاربر و به منظور جلوگیری از هرگونه سوء استفاده ای گرفته شده و تماما نزد اینتکس محفوظ خواهند ماند. کاربران در سه سطح اطلاعات مختلفی را برای دسترسی به هریک از موارد مشخص شده در ذیل ارسال خواهند نمود.
            </p>
            <ol type="1">
              <li>
                سطح اول که شامل تنها دریافت ارز های دیجیتالی می باشد به صورت پیش فرض پس از ارسال ایمیل و رمز عبور به منظور ثبت نام فعال خواهد شد. ایمیل ها نزد اینتکس محفوظ خواهند بود . رمزهای عبور تماما به صورت درهمریخته ذخیره خواهند شد و به هیچ وجه قابل خواندن نمی باشند.
              </li>
              <li>
                در سطح ۲ کاربران می توانند با ارسال نام و نام خانوادگی ، کد ملی و شماره تلفن همراهی که منتسب به همان کد ملی است، درخواست فعال سازی این سطح را ارسال نمایند. سامانه پس از تایید اطلاعات ، سطح کاربر را افزایش و درصورت عدم تایید مراتب را اعلام خواهد نمود. کد ملی و شماره همراه نیز نزد سامانه اینتکس به صورت محرمانه محفوظ خواهد بود
              </li>
              <li>
                با توجه به موارد گفته شده و به دلیل پیروی از قوانین پول شویی، کاربران در سطح ۳ به منظور واریز و برداشت ریالی می بایست اطلاعاتی مانند شهر،استان و آدرس محل سکونت و شماره ثابت خود را همراه با یک کارت بانکی منتسب به شماره ملی وارد شده در سطح ۲ وارد نماید. علاوه بر این اطلاعات می بایست تصویر کارت ملی و تصویری شامل دست نوشته ای که در سامانه مشخص شده را به صورت واضح و خوانا و قابل تصدیق ارسال نمایند. اطلاعات در نزد سامانه محرمانه خواهند بود و تصاویر به صورت غیر قابل دسترس برای غیر ذخیره می گردد.
              </li>
            </ol>
            <p>
              اطلاعات وارده تنها به منظور تصدیق هویت کاربر می باشد و در دسترس هیچ شخص ثالثی قرار نخواهد گرفت. موارد ذکر شده تنها در موارد خاص و نیاز و به دستور مستقیم مراجع قضایی ، با توجه به درخواست، به صورت محرمانه تقدیم ایشان خواهد گردید.
              در آخر نیز باید ذکر گردد کاربران سامانه می بایست حداقل سن قانونی 18 سال تمام را دارا باشند و تمامی اطلاعات هویتی مختص خود ایشان باشد. درصورت استفاده یک کاربر از اکانت کاربر دیگر، تمامی خطرات و عواقب آن بر عهده صاحب حساب می باشد.
            </p>
          </Typography>
        </Container>
      </div>
    </div>
  );
}
