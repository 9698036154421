import { makeStyles, createStyles } from '@material-ui/core/styles';
import terms from '../assets/images/terms.jpg';

export const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column'
		},
		image: {
			width: '100vw',
			height: '15rem',
			backgroundImage: `url(${terms})`,
			backgroundPosition: '60% 30%',
			backgroundSize: 'cover',
			zIndex: -1
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
			width: '100vw',
			height: '15rem',
			position: 'absolute',
			zIndex: 2
		},
		header: {
			display: 'flex',
			position: 'absolute',
			zIndex: 6,
			color: 'white',
			fontWeight: 100,
			width: '100vw',
			height: '15rem',
			marginBottom: '25.5vh',
			justifyContent: 'center',
			textAlign: 'center',
			fontSize: '5rem',
			[theme.breakpoints.down('sm')]: {
				fontSize: '4rem'
			},
			'& p':{
				height:"15rem",
				lineHeight: "12rem",
				fontSize: '3rem !important',
				margin:"0 auto"
			}
		},
		container: {
			backgroundColor: 'white',
			padding: '3rem',
			position: 'relative',
			top: '-3rem',
			zIndex: 9,
			"box-shadow":"0px 0px 13px 0px rgb(0 0 0 / 14%) !important",
			"margin-bottom":"3rem !important",
			borderRadius: '10px',
			'& p': {
				lineHeight: 2,
				fontSize: '1.2rem'
			}
		}
	})
);
